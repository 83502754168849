import { useTheme } from '@emotion/react'
import Image from 'next/image'

import requests from '@/lib/requests'
import { useUTMContext } from '@/lib/gtm'

import { QuotationSuccessFeedbackModal } from '@/modules/landing/components/Modal'
import { useDisclosure } from '@/lib/hooks'

import { FormComponent } from './FormComponent'

type Props = {
  id?: string
}

export function QuotationForm({ id }: Props) {
  const theme = useTheme()

  const { data: utm } = useUTMContext()

  const disclosure = useDisclosure()

  return (
    <section
      css={theme.mq({
        position: 'relative',
        width: '--sizes-full',
        backgroundColor: '--colors-primary-100',
      })}
    >
      <div
        id={id}
        css={theme.mq({
          ...theme.layerStyles.landingSectionContainer,
          display: 'grid',
          gridGap: { base: 0, md: '--space-10' },
          gridTemplateColumns: {
            base: '1fr',
            md: '0.45fr 0.55fr',
          },
          position: 'relative',
        })}
      >
        <figure
          css={theme.mq({
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '45%',
            height: '460px',
            display: {
              base: 'none',
              md: 'block',
            },
          })}
        >
          <Image
            src="/images/landing/get-quotation-form-bg.svg"
            alt=""
            layout="fill"
            sizes="50%"
            objectPosition="left"
          />
        </figure>
        <div
          css={theme.mq({
            position: 'relative',
            textAlign: {
              base: 'center',
              md: 'left',
            },
          })}
        >
          <h2
            css={theme.mq({
              ...theme.textStyles.landingSectionHeader,
              marginBottom: { base: 0, sm: '--space-2', md: '--space-4' },
              color: '--colors-primary-500',
            })}
          >
            มีข้อสงสัยเพิ่มเติม ?
          </h2>
          <p
            css={theme.mq({
              ...theme.textStyles.landingSectionSubheader,
              br: {
                display: {
                  base: 'none',
                  md: 'block',
                },
              },
            })}
          >
            มีข้อสงสัยเพิ่มเติมหรือต้องการใบเสนอราคา
            <br /> ติดต่อเราได้เลย
          </p>
        </div>
        <FormComponent
          onSubmit={async (data) => {
            try {
              await requests.post('/api/quotations', { ...data, utm }, {})

              disclosure.onOpen()
            } catch {}
          }}
        />
      </div>
      <QuotationSuccessFeedbackModal disclosure={disclosure} />
    </section>
  )
}
